import { Button, Form } from "react-bootstrap";

import React from "react";
import api from "../modules/api";

export default function LeadMagnet() {
  const [disabled, setDisabled] = React.useState(false);
  const [email, setEmail] = React.useState("");

  function handleSubmit(e) {
    e.preventDefault();
    setDisabled(true);

    return api
      .contact({ form_name: "leadmag", email })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setDisabled(false);
        window.location.href = ASSET_URL;
      });
  }
  return (
    <Form
      as="form"
      name="contact"
      onSubmit={handleSubmit}
      className="d-flex flex-row w-100"
      style={{ maxWidth: 500 }}
    >
      <div className="w-100">
        <Form.Label hidden>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Email"
          className="border-hard-right border-right-0 h-100 w-100 font-size-cta"
          required
          disabled={disabled}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div>
        <Button
          size="lg"
          className="border-hard-left py-3 px-4 font-size-cta"
          type="submit"
        >
          Download
        </Button>
      </div>
    </Form>
  );
}
const ASSET_URL = "/content/high-quality-api-integration-checklist-webhookdb.pdf";

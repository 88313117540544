import { Card, CardImg, Col, Row } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";

import RLink from "./RLink";
import React from "react";
import dayjs from "dayjs";

/**
 * Render recent blog posts.
 * If excludePath is given, do not show that post.
 * Used for showing recent posts on a blog post page.
 */
export default function RecentBlogPosts({ excludePath }) {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { contentType: { eq: "blog" }, frontmatter: { draft: { eq: false } } }
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 5
      ) {
        edges {
          node {
            contentType
            frontmatter {
              path
              title
              summary
              date
              image
              imageAlt
            }
          }
        }
      }
    }
  `);
  const recentPosts = data.allMarkdownRemark.edges
    .map((e) => e.node)
    .filter((b) => b.frontmatter.path !== excludePath);
  if (recentPosts.length === 5) {
    recentPosts.pop();
  }
  return (
    <Row>
      {recentPosts.map((node) => {
        let blog = node.frontmatter;
        const dateFmt = dayjs(blog.date).format("MMMM D, YYYY");
        return (
          <Col key={blog.path} xs={12} sm={6} lg={3} className="mb-4">
            <Card className="h-100">
              <RLink to={blog.path}>
                <CardImg
                  variant="top"
                  src={`/content/blog/thumbnail/${blog.image}`}
                  alt={blog.imageAlt}
                  height="200px"
                  style={{ objectFit: "cover" }}
                ></CardImg>
              </RLink>
              <Card.Body>
                <RLink to={blog.path}>
                  <Card.Title className="mb-2 text-dark">{blog.title}</Card.Title>
                </RLink>
                <p title={blog.date} className="mb-2 text-muted">
                  {dateFmt}
                </p>
                <Card.Text className="mb-2">{blog.summary}</Card.Text>
                <RLink to={blog.path} className="mb-0 font-weight-bold">
                  Read More →
                </RLink>
              </Card.Body>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
}

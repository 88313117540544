import "../styles/custom.scss";

import { Anchor, SafeExternalLink } from "../components/links";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  IoIceCreamOutline,
  IoLogoGithub,
  IoRadio,
  IoRocketOutline,
} from "react-icons/io5";
import { IoIosCode, IoMdStopwatch } from "react-icons/io";

import API2SQL from "../components/API2SQL";
import { BiHappyHeartEyes } from "react-icons/bi";
import Centered from "../components/Centered";
import CenteredDiv from "../components/CenteredDiv";
import Helmet from "react-helmet";
import Hilite from "../components/Hilite";
import LayoutPage from "../components/LayoutPage";
import Lead from "../components/Lead";
import LeadMagnet from "../components/LeadMagnet";
import { Link } from "gatsby";
import RLink from "../components/RLink";
import React from "react";
import RecentBlogPosts from "../components/RecentBlogPosts";
import RoundHook from "../images/hook-onblue-round.png";
import Seo from "../components/Seo";
import Waves from "../components/Waves";
import Webterm from "../components/Webterm";
import staticData from "../components/staticData";

export default function IndexPage() {
  return (
    <LayoutPage center>
      <Seo title="" meta={[{ name: "keywords", content: keywords }]} />
      <Helmet>
        <title>WebhookDB</title>
      </Helmet>
      <Container className="bg-primary text-light pt-5 px-4" fluid>
        <Centered>
          <CenteredDiv>
            <h1 className="text-center">Sync API to Your Database</h1>
          </CenteredDiv>
          <Lead>
            Ever wish <Hilite>querying an API</Hilite> can be as fast and flexible as{" "}
            <Hilite>querying SQL?</Hilite>
          </Lead>
          <Lead>
            WebhookDB will <Hilite>synchronize, schematize, and normalize</Hilite> any
            3rd party API <Hilite>into your database</Hilite>, entirely on your
            infrastructure.
          </Lead>
          <Lead>
            API data in your own database means better performance, security, and
            reliability. And it opens up simpler programming patterns, since your
            database, not an API, can be on a critical path.
          </Lead>
          <div className="d-flex justify-content-center mt-5 mb-5 gap-3">
            <Button
              size="lg"
              className="py-3 px-4 font-size-cta"
              href="/terminal"
              variant="secondary"
              as={RLink}
            >
              <span className="text-nowrap">Start for free</span>
            </Button>
            <Button
              size="lg"
              className="py-3 px-4 font-size-cta d-flex align-items-center"
              href={staticData.repo}
              variant="outline-light"
              as={Anchor}
            >
              <IoLogoGithub />
              &nbsp;On GitHub
            </Button>
          </div>
        </Centered>
      </Container>
      <Waves />
      <ContainerPlain>
        <Centered>
          <h2>You&rsquo;ll ❤️ WebhookDB.</h2>
          <Lead>
            High-performing engineering teams use WebhookDB&rsquo;s revolutionary{" "}
            <RLink to={staticData.api2sql}>{API2SQL} technology</RLink> and get{" "}
            <Hilite>instant integration superpowers.</Hilite>.
          </Lead>
          <Row>
            {testimonials.map((t) => (
              <Col xs={12} key={t.by}>
                <Testimonial {...t} />
              </Col>
            ))}
          </Row>
          <div className="d-flex justify-content-center mt-4 mb-2">
            <Button
              size="lg"
              className="py-3 px-5 font-size-cta"
              href={staticData.introVideo}
              variant="primary"
              as={SafeExternalLink}
            >
              Watch intro video
            </Button>
          </div>
        </Centered>
      </ContainerPlain>
      <ContainerColor>
        <a id="integration-checklist" />
        <Centered>
          <div className="d-flex align-items-center mb-2">
            <img
              src={RoundHook}
              height={64}
              alt="webhook db hook logo"
              className="mr-3"
            />
            <h3 className="mb-0">Download your API Integration Checklist</h3>
          </div>
          <Lead>
            We&rsquo;ve written a FREE{" "}
            <strong>&ldquo;Checklist for High Quality API Integrations&rdquo;</strong>{" "}
            that helps you avoid the most costly but subtle mistakes you&rsquo;re likely
            to make when building your own API integrations.
          </Lead>
          <Lead className="mb-5">
            Easily improve <Hilite>your own API integrations</Hilite> 100x.
          </Lead>
          <div className="d-flex flex-row justify-content-start pt-2 mb-3">
            <LeadMagnet />
          </div>
        </Centered>
      </ContainerColor>
      <ContainerPlain>
        <Centered>
          <Row>
            {features.map((f) => (
              <Feature key={f.title} {...f} />
            ))}
          </Row>
        </Centered>
      </ContainerPlain>
      <ContainerColor>
        <Centered>
          <h2>📖 Our Guide.</h2>
          <Lead>
            We place a strong emphasis on clear documentation and intuitive, efficient
            tooling. You can get up and going in minutes, either locally with{" "}
            <a href={staticData.repo}>open source</a>, or using{" "}
            <Link to="/terminal">WebhookDB Cloud</Link>. Read our documentation for more
            information.
          </Lead>
          <Button
            variant="secondary"
            size="lg"
            className="cta mt-2 mb-2"
            href={staticData.docs()}
          >
            Start quickly
          </Button>
        </Centered>
      </ContainerColor>
      <ContainerPlain>
        <Centered>
          <h2>Try It. 🚲</h2>
          <Lead>
            Instead of a web interface, you log into WebhookDB through a command-line
            interface (CLI). The CLI is{" "}
            <Link to="/download">available to download</Link>, or you can run it in your
            browser.
          </Lead>
          <Lead>
            Use the terminal window below, or go to the{" "}
            <Link to="/terminal">dedicated terminal page</Link>.
          </Lead>
          <Webterm className="mb-2" loading="lazy" width="100%" height="300" />
        </Centered>
      </ContainerPlain>
      <ContainerColor>
        <Centered>
          <h2>Get In Touch. ✍️</h2>
          <Lead>
            WebhookDB is open source and primarily built by a an independent,
            bootstrapped team. So it&rsquo;s extra special when we hear from users.
          </Lead>
          <Button variant="primary" size="lg" className="cta mt-2 mb-3" href="/contact">
            Get in touch
          </Button>
        </Centered>
      </ContainerColor>
      <ContainerPlain>
        <Link to="/blog">
          <h3 className="text-center text-dark">Recent Blog Posts</h3>
        </Link>
        <Centered>
          <RecentBlogPosts />
        </Centered>
      </ContainerPlain>
    </LayoutPage>
  );
}

const ContainerPlain = (props) => <Container fluid className="px-4 py-5" {...props} />;
const ContainerColor = (props) => (
  <Container fluid className="px-4 py-5 bg-primary-light" {...props} />
);

function Feature({ icon, title, text }) {
  const Icon = icon;
  return (
    <Col xs={12} sm={6} md={4} className="text-center">
      <div style={{ fontSize: "2.5rem" }} className="mb-2 text-primary">
        <Icon />
      </div>
      <h4>{title}</h4>
      <p>{text}</p>
    </Col>
  );
}

const features = [
  {
    icon: IoMdStopwatch,
    title: "100x Less Effort",
    text: (
      <>
        Instead of 6 days or 6 hours, integrate literally any API in 6 minutes or 6
        seconds.
      </>
    ),
  },
  {
    icon: IoRocketOutline,
    title: "100x Faster",
    text: (
      <>
        Data is instantly available in your database. Query it in microseconds, instead
        of a remote 3rd party server.
      </>
    ),
  },
  {
    icon: IoRadio,
    title: "Speak SQL",
    text: (
      <>
        No new frameworks to learn &mdash; speak the SQL you love, since API data is in
        your database.
      </>
    ),
  },
  {
    icon: IoIceCreamOutline,
    title: "Lowest Cost",
    text: (
      <>
        Free or affordable shared hosting, or one-time fee to run easily and efficiently
        on your own infrastructure.
      </>
    ),
  },
  {
    icon: IoIosCode,
    title: "Integrate Any API",
    text: (
      <>
        Choose from dozens of our pre-built replicators, or write your own for literally
        any API.
      </>
    ),
  },
  {
    icon: BiHappyHeartEyes,
    title: "Eliminate Complexity",
    text: (
      <>
        Patterns like <RLink to={staticData.api2sql}>{API2SQL}</RLink> and{" "}
        <RLink to={staticData.httpsync}>HTTP Sync</RLink> eliminate complexity in your
        application.
      </>
    ),
  },
];

const testimonials = [
  {
    text: (
      <>
        WebhookDB simplified hundreds of lines and several minutes of API calls to our
        fintech partners into a <strong>single SQL query taking milliseconds.</strong>
      </>
    ),
    by: "Kirill Moizik, CTO",
    company: "Boom",
    href: "https://boompay.app",
  },
  {
    text: (
      <>
        Our in-house solution for calendar syncing was falling over and taking time away
        from more important features. Migrating to WebhookDB allowed us to{" "}
        <strong>quickly get our core features into a scalable state</strong>. On top of
        that, our architecture got simpler.
      </>
    ),
    by: "Jason Nelson, Engineering Manager",
    company: "Hearth",
    href: "https://hearthdisplay.com",
  },
  {
    text: (
      <>
        <strong>
          Building our own integrations is boring, repetitive, and time-consuming.
        </strong>{" "}
        Worse still, debugging a breaking issue every couple months is an annoying
        time-sink. WebhookDB was reliable and easy to extend.
      </>
    ),
    by: "Kevin Kuchta, Lead Engineer",
    company: "Daybreak Health",
    href: "https://www.daybreakhealth.com/",
  },
];

function Testimonial({ text, by, company, href }) {
  return (
    <div className="lead my-4">
      <blockquote className="blockquote">
        <p className="mb-2">{text}</p>
        <em className="ml-2 text-muted">
          &mdash; {by},{" "}
          <SafeExternalLink href={href} className="text-muted text-underline">
            {company}
          </SafeExternalLink>
        </em>
      </blockquote>
    </div>
  );
}

const keywords = [
  "webhook",
  "api integration",
  "api sync",
  "api replication",
  "api postgres sync",
  "api2sql",
  "graphql",
  "stripe api webhooks",
  "plaid webhooks",
  "google calendar webhooks",
  "twilio webhooks",
  "signal wire api",
  "github webhooks",
].join(", ");
